
import template from './chart-view.html';

/**
 * Charts List VM
 */
class ChartViewPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.chart_id = ko.observable();
	}

	async update()
	{
	}
}

/**
 * page class
 */
class ChartViewPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ChartViewPageViewModel(this);
		this.name = 'ChartViewPageClass';

		this.viewModel.chart_id(bindings.chart_id);
	}

	async init()
	{
	}

	async updateData()
	{
		await this.viewModel.update();
	}

	async teardown()
	{
	}
}

export default {
	route: '[/]charts/chart-view',
	page_class: ChartViewPageClass,
	template: template
}
