
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import appcaches from './caches/index.js';
import plugins from './plugins/index.js';

import package_json from '../package.json';

(function() {

	window.Grape.modules.push(package_json);

//	Grape.utils.register_caches(appcaches);
//	Grape.utils.register_components(components);
//	Grape.utils.register_dialogs(components);
	Grape.utils.register_pages(pages);
//	Grape.utils.register_plugins(plugins);

})();

