
import template from './charts-list.html';

/**
 * Charts List VM
 */
class ChartsListPageViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;
	}

	async update()
	{
	}
}

/**
 * page class
 */
class ChartsListPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new ChartsListPageViewModel(this);
		this.name = 'ChartsListPageClass';
	}

	async init() {
	}

	async updateData() {
		await this.viewModel.update();
	}

	async teardown() {
	}
}

export default {
	route: '[/]charts/charts-list',
	page_class: ChartsListPageClass,
	template: template
}
